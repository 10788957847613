import React from 'react'
import LikeButton from '../../Ui/LikeButton'
import { getHours } from '../../../../utils/formattedDate'
import { getMinuteDifference } from '../../../../utils/ranges'

const CalendarCard = ({
  stage,
  id,
  title,
  time,
  rangeSize,
  path,
  to,
  sizeOffset
}) => {
  const cardSizeOffset = sizeOffset || 4
  const cardWidth = rangeSize * (getMinuteDifference(time.start, time.end) / 30)
  const cardStart = rangeSize * (getMinuteDifference(time.rangeStart, time.start) / 30)
  const timeRange = `${getHours(time.start, ':')} - ${getHours(time.end, ':')}`
  return (
      <div style={{ height: '80px', width: `calc(${cardWidth}px - ${cardSizeOffset}px)`, left: `calc(${cardStart}px + ${(cardSizeOffset + 1) / 2}px)` }} className={'top-1/2 -translate-y-1/2 absolute bg-white text-purple-wolf2025 p-1 uppercase'}>
        <div className="font-openSans tracking-tight text-xs">{timeRange}</div>
        <div className={`font-obviously font-regular tracking-tighter max-h-12 overflow-hidden text-xs !leading-3 py-1`}>
          {to ? <a href={`/${path}/${to}`}>{title}</a> : title}
        </div>
        <LikeButton uid={id} colorInverted={true} className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''} absolute right-1 top-1 w-4`} />
          {
              stage ? <div className={`font-openSans font-bold tracking-[-1px] ${(cardWidth <= 128) ? 'text-xxs mt-0' : 'text-xs mt-1'} text-pink-wolf2025 leading-none`}>{stage}</div> : null
          }
      </div>
  )
}

export default CalendarCard
