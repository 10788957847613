import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const BannerWithMobileAppDownloads = ({ title }) => {
  return (
    <header className="header lg:px-20 pt-20 leading-none hidden md:block relative text-center">
      <h1 className="mb-2">{ title }</h1>
      <div className="w-full flex relative">
        <div className="w-2/3 flex items-center mx-auto">

          <div className='w-2/12'></div>
          {/* APP STORE LINK */}
          <a className="w-2/12 flex-shrink-0 pl-4 pr-1"
             href="https://apps.apple.com/ca/app/le-festif/id895648726"
             target="_blank"
             rel="noreferrer">
            <StaticImage className={'max-w-[250px]'}
                          src={'../../../images/stores/appstore-logo.png'}
                          placeholder="blurred"
                          objectFit="contain"
                          alt="Logo App Store"/>
          </a>

          {/* GOOGLE PLAY LINK */}
          <a className="w-2/12 flex-shrink-0 pr-4 pl-1"
             href="https://play.google.com/store/apps/details?id=ca.lefestif&hl=fr_CA&gl=US"
             target="_blank"
             rel="noreferrer">
            <StaticImage className={'max-w-[250px]'}
                          src={'../../../images/stores/googleplay-logo.png'}
                          placeholder="blurred"
                          objectFit="contain"
                          alt="Logo Google Play Store" />
          </a>

          {/* PROMO TEXT */}
          <p className="w-6/12 font-obviously font-light italic text-[14px] text-left text-white uppercase">Télécharge l’application mobile pour recevoir les notifications des Spectacles Imprévisibles Loto-Québec</p>
       
          <div className='w-2/12'></div>

        </div>
      </div>
    </header>
  )
}

export default BannerWithMobileAppDownloads
