import React, { useContext, useMemo, useCallback, useEffect, useRef } from 'react'
import { HorizontalScrollView } from '../../../Utils/HorizontalScrollView'
import CalendarTab from './CalendarTab'
import { calculateHourRange, groupNonOverlappingSchedules } from '../../../../utils/ranges'
import { CalendarTabContext } from './Provider/CalendarTabProvider'
import CalendarCard from './CalendarCard'
import { FilterContext } from '../Providers/FilterProvider'
import { getHours } from '../../../../utils/formattedDate'
import slugify from 'slugify'
import { FestifPrimaryMenuTailwindCssHeight, FestifPrimaryMenuTailwindCssStickyBackgroundColor, FestifPrimaryMenuTailwindCssStickyOpacity } from '../../Common/Header/FestifPrimaryMenu'


/**
 * @todo filter by strength
 * @param stages
 * @param eventsGroups
 * @returns {JSX.Element}
 * @constructor
 */
const CalendarDesktop = ({ stages, eventsGroups }) => {
  const { filter } = useContext(FilterContext)
  const { calendarIndex, setCalendarIndex } = useContext(CalendarTabContext)

  useEffect(() => {
    setCalendarIndex(0)
  }, [filter, setCalendarIndex])
  const currentEventGroup = useMemo(() => {
    return eventsGroups[calendarIndex]
  }, [eventsGroups, calendarIndex])

  const currentDateRange = useMemo(() => {
    return currentEventGroup
      ? calculateHourRange(
        currentEventGroup?.start_date_raw,
        currentEventGroup?.end_date_raw,
        30
      )
      : []
  }, [currentEventGroup])

  // Create a memoized Set of stage names from the 'stages' array using useMemo to avoid unnecessary re-renders.
  const stageNameSet = useMemo(() => {
    return new Set(stages?.edges?.map((stage) => stage.node?.data?.title?.text))
  }, [stages])

  /**
     * Checks if a given stage name is included in the set of available stages.
     * @param {string} stageName - The name of the stage to check.
     * @returns {boolean} - True if the stage is available, false otherwise.
     */
  const isInStages = useCallback((stageName) => {
    return stageNameSet.has(stageName)
  }, [stageNameSet])

  /**
     * Returns an array of events that belong to a particular stage, or events that don't belong to any of the stages.
     * @param {Array} appearances - An array of appearances, where each appearance is an array of events.
     * @param {string} stage - A string representing the stage to filter by.
     * @returns {Array} An array of events that belong to a particular stage, or events that don't belong to any of the stages.
     */
  const getEventsByStage = useCallback((appearances, stage) => {
    return appearances
      .flatMap(events => // Flatten the array of arrays of events into a single array of events
        events.filter(event => { // Filter the events based on whether they belong to the specified stage
          // const eventStage = event.data?.event?.document?.data?.stage?.document?.data?.title?.text
          // return (!stage && !isInStages(eventStage)) || (eventStage === stage)
          return true
        })
      )
  }, [isInStages])

  const otherEvents = useMemo(() => currentEventGroup && groupNonOverlappingSchedules(getEventsByStage(currentEventGroup?.appearances).map(item => {
    // Make sure we use event time if appearance time is not populate
    const startTime = item.data.time ? item.data.time : item.data.event.document.data.start_time
    return ({
      startHour: new Date(startTime).getTime(),
      endHour: new Date(item.data.end_hour).getTime(),
      ...item
    })
  }
  )), [getEventsByStage, currentEventGroup])

  const tabsDefaultHorizontalMargin = 'mx-4'
  const tabsDefaultRoundCorners = 'rounded-t-3xl'
  const tabsDefaultBackgroundColor = 'bg-white/20'
  const tabsContainerRef = useRef(null)
  let tabsContainerHasStickyClass = false
  const handleScroll = () => {
    const tabsContainer = tabsContainerRef.current
    if (tabsContainer) {
      const offsetTop = tabsContainer.offsetTop
      const shouldBySticky = window.scrollY > (offsetTop - 1) // 1 is to make sure it doesn't flick when scrolling
      if (shouldBySticky && !tabsContainerHasStickyClass) {
        tabsContainer.classList.add('sticky-tabs')
        tabsContainer.classList.add(FestifPrimaryMenuTailwindCssHeight)
        tabsContainer.classList.add(FestifPrimaryMenuTailwindCssStickyBackgroundColor)
        tabsContainer.classList.add(FestifPrimaryMenuTailwindCssStickyOpacity)
        tabsContainer.classList.remove(tabsDefaultHorizontalMargin)
        tabsContainer.classList.remove(tabsDefaultRoundCorners)
        tabsContainer.classList.remove(tabsDefaultBackgroundColor)
        tabsContainerHasStickyClass = true
      } else if (!shouldBySticky && tabsContainerHasStickyClass) {
        tabsContainer.classList.remove('sticky-tabs')
        tabsContainer.classList.remove(FestifPrimaryMenuTailwindCssHeight)
        tabsContainer.classList.remove(FestifPrimaryMenuTailwindCssStickyBackgroundColor)
        tabsContainer.classList.remove(FestifPrimaryMenuTailwindCssStickyOpacity)
        tabsContainer.classList.add(tabsDefaultHorizontalMargin)
        tabsContainer.classList.add(tabsDefaultRoundCorners)
        tabsContainer.classList.add(tabsDefaultBackgroundColor)
        tabsContainerHasStickyClass = false
      }
    }
  }

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleScroll)
    }
  }, [])

  return (
      <div className="hidden lg:block pb-6">
        <nav className={`font-obviously text-xl ${tabsDefaultHorizontalMargin} ${tabsDefaultRoundCorners} ${tabsDefaultBackgroundColor}`} ref={tabsContainerRef}>
          <HorizontalScrollView updateIndex={filter} notOverlappingClassName="justify-center" activateNdinativeScroll={true} useWheel={true} className="flex px-24 py-2">
            {eventsGroups.map(({ start_date_raw: startDateRaw }, i) => {
              return (
                  <CalendarTab
                      key={startDateRaw + i}
                      tabIndex={i}
                      date={startDateRaw}
                  />
              )
            })}
          </HorizontalScrollView>
        </nav>
        <div className="event-artist rounded-b-3xl bg-white/20 mx-4">
          <div className="flex pb-16 px-4">
            <div style={{height: `${otherEvents ? otherEvents.length * 80 : 80}px`}} className="mb-20"></div>
            <HorizontalScrollView arrowPower={0.5} overlappingClassName='pl-8' arrowClassName="!top-11 !h-[calc(100%-4rem)]" showArrow={true} updateIndex={`${filter}-${calendarIndex}`} showScrollbar={true} className="flex flex-col relative" activateNativeScroll={true} >
              <div className="flex text-white font-poppins text-lg mb-4">
                {
                    currentDateRange && currentDateRange?.map((date) => {
                      return <div key={`date-range-${date}`} className="w-32 shrink-0 -translate-x-1/2 text-center first:text-left first:translate-x-0">{getHours(date, ':')}</div>
                    })
                }
              </div>

              <div className="h-full relative">
                <div style={{ width: `${8 * currentDateRange.length}rem` }} className={'flex flex-col min-w-full absolute z-10'}>
                  {
                    otherEvents && otherEvents.map((group, i) => {
                      return <div key={`other-events-${i}`} className="h-[80px] flex relative even:bg-white/10 my-[2px]">
                        {
                          group.map((appearance) => {
                            const { title, end_hour: endHour } = appearance.data
                            const { is_show: isShow } = appearance.data?.event?.document?.data
                            const { hidefromprog: hideFromShow } = appearance.data?.event?.document?.data
                            const artisteName = appearance.data?.artist?.document?.data?.title?.text
                            const artistSlug = artisteName ? slugify(artisteName).toLowerCase().replace("'", '').replace('.', '') : ''
                            const eventName = appearance.data?.event?.document?.data?.title?.text
                            const eventSlug = eventName ? slugify(eventName).toLowerCase().replace("'", '').replace('.', '') : ''
                            const path = artisteName ? 'artistes' : eventName ? 'evenements' : undefined
                            const slug = (artisteName ? artistSlug : eventName ? eventSlug : undefined)?.replace(/[()]/g, '');
                            const stage = appearance.data?.event?.document?.data?.stage?.document?.data?.title?.text

                            // In case Appearance object don't have the 'Time' property, we use the event 'start_time' property instead
                            let time = appearance.data?.time
                            if (!time) {
                              time = appearance.data?.event?.document?.data?.start_time
                            }
                            if (!hideFromShow) {
                              return <CalendarCard key={`other-calendar-card-${title?.text}`} id={appearance.prismicId} path={path} to={slug} isShow={isShow} title={title?.text} stage={stage} rangeSize={128} time={{ rangeStart: currentDateRange[0], start: time, end: endHour }} />
                            }

                            return <></>
                          })
                        }
                      </div>
                    })
                  }
                </div>
                <div className="flex h-full">
                  {
                      currentDateRange && currentDateRange?.map((date) => {
                        return <div key={`date-col-range-${date}`} className="w-32 shrink-0 last:w-0 first:before:content-none relative before:absolute before:left-0 before:top-0 before:h-full before:w-0.5 before:bg-white/20"></div>
                      })
                  }
                </div>
              </div>

            </HorizontalScrollView>
          </div>

        </div>
      </div>

  )
}

export default CalendarDesktop

