import React, { useContext, useEffect, useState } from 'react'
import ArtistThumbnail from './ArtistThumbnail'
import { FilterContext } from './Providers/FilterProvider'
import { EventsContext } from '../Customer/Providers/CustomerEventsProvider'
import EmptyMessage from '../Ui/EmptyMessage'
import { FILTER_SELECTION, FILTER_FREE, FILTER_ACTIVITY, FILTER_ALPHABETICAL } from './Calendar/Constants'
import ArtistActivities from './ArtistActivities'

const getAppearancesByArtistId = (data, artistId) => {
  const appearances = []
  data.allPrismicAppearances.group
  .map(({ edges }) => {
    return (edges.map(({ node }) => {

      if(node?.data?.artist?.document?.id === artistId) {
        appearances?.push(node)
      }
    }))
  })

  return appearances
};

// const getAppearancesByArtistId = (data, artistId) => {
//   const appearances = [];
//   const seenIds = new Set();

//   data.allPrismicAppearances.group.forEach(({ edges }) => {
//     edges.forEach(({ node }) => {
//       const id = node?.id;
//       if (
//         node?.data?.artist?.document?.id === artistId &&
//         id &&
//         !seenIds.has(id)
//       ) {
//         appearances.push(node);
//         seenIds.add(id);
//       }
//     });
//   });

//   return appearances;
// };

const Artists = ({ data }) => {
  const { filter, searchTerm } = useContext(FilterContext)
  const { includeUid } = useContext(EventsContext)
  const [artists, setArtists] = useState([])

  useEffect(() => {
    /**
         * Check if artist is liked according to his appearances
         * @param artisteId
         * @returns {boolean}
         */
    const isLikedArtist = (artisteId) => {
      return data?.allPrismicAppearances?.group?.filter(({ edges }) => {
        return edges?.filter(({ node }) => {
          const { id } = node?.data?.artist?.document !== null ? node?.data?.artist?.document : false
          if (id) {
            return artisteId === id && includeUid(node?.prismicId)
          } else {
            return false
          }
        }).length > 0
      }).length > 0
    }

    const artistIsFree = (artistId) => {
      for (const element of data?.allPrismicAppearances?.group || []) {
        for (const { node } of element?.edges || []) {
          if (artistId === node?.data?.artist?.document?.id) {
            return node?.data?.event?.document?.data?.free
          }
        }
      }
      return false
    }

    /**
     * Get list of artists filtered according to which filter is
     * selected
     * For "Mes favoris" filter check if an appearances of the artist is liked
     * @param artists
     * @returns {*}
     */
    const getFilteredArtists = (artists) => {
      const filteredArtists = artists?.filter(({ node }) => {
        const isFree = artistIsFree(node?.id)
        const isRueFestive = node.data?.rue_festive_only
        const isMiscActivities = node.data?.activite_diverses
    
        return (
          (filter === null) || // Aucun filtre appliqué
          (filter === FILTER_FREE && isFree) ||
          (filter === FILTER_ACTIVITY && (isRueFestive || isMiscActivities)) ||
          (filter === FILTER_SELECTION && isLikedArtist(node.id)) ||
          (filter === FILTER_ALPHABETICAL)
        )
      })
    
      // Alphabetical order
      if (filter === FILTER_ALPHABETICAL) {
        filteredArtists.sort((a, b) =>  {
          return a.node.data.title.text.localeCompare(b.node.data.title.text, 'fr', { sensitivity: 'base' })
        })
      }

      // Apply search text
      if(undefined !== searchTerm && searchTerm.length > 0) {
        const searchedArtists = filteredArtists.filter(({ node }) => 
          node.data.title.text.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return searchedArtists;
      }
    
      return filteredArtists
    }    

    setArtists(getFilteredArtists(data?.allPrismicArtists?.edges))
  }, [filter, data, includeUid, searchTerm])

  return (
      <div className="lg:block w-full max-w-[1920px] md:px-36 px-4 xl:mx-auto pb-12">
        {
          filter == FILTER_ACTIVITY 
          ?
          <div>
            <ArtistActivities />
          </div>
          :
          <EmptyMessage className="text-center text-xl text-white py-16 px-8" customText="artiste" dynamicMessage={filter} condition={artists && artists.length > 0}>
              <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-16 lg:!mt-[2rem]">
                    {
                      artists?.map(({ node }) => {
                        const { title, main_image: MainImage } = node.data
                        var appearances = getAppearancesByArtistId(data, node.id);
                        var events = [];
                        appearances?.forEach(appearance => {
                          // Ne pas ajouter les évènements dont la propriété appearance?.data?.event?.document?.id est déjà présente
                          events.push(appearance?.data?.event);
                        });
                        return (
                          <ArtistThumbnail
                              key={node.id}
                              title={title.text}
                              events={events}
                              image={MainImage}
                              to={`/artistes/${node.slug}`}/>
                        )
                    })                    
                  }
              </section>
          </EmptyMessage>
        }
      </div>
  )
}

export default Artists
