import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const ArtistActivities = () => {
  return (
    <center>
        <div className='main-page-project'>
            <div className="flex flex-col md:grid md:grid-cols-5 md:grid-rows-1 md:gap-[20px] gap-[5px] mt-6">
                
                <a className="main-page-project-box mb-6 group relative w-full rounded-3xl overflow-hidden row-span-1 col-span-1 border-4 border-purple-wolf2025 bg-purple-wolf2025" 
                    href="/infos/entrevues#info_content" >
                    <StaticImage src="../../../images/prog/entrevues.jpg" alt="Entrevue en plein air." className="w-full h-full object-cover" />
                    <div className="main-page-project-box-inner absolute bottom-[-1px] left-0 w-full my-auto bg-yellow-wolf2025 !text-left text-xl py-2 flex flex-col">
                        <h2 className='font-obviously font-black uppercase tracking-wider !text-lg !leading-auto'>Entrevues</h2>
                    </div>
                </a>

                <a className="main-page-project-box mb-6 group relative w-full rounded-3xl overflow-hidden row-span-1 col-span-1 border-4 border-purple-wolf2025 bg-purple-wolf2025" 
                    href="/infos/famille#info_content" >
                    <StaticImage src="../../../images/prog/activitesfamiliales.jpg" alt="Enfants faisant du coloriage." className="w-full h-full object-cover" />
                    <div className="main-page-project-box-inner absolute bottom-[-1px] left-0 w-full my-auto bg-pink-wolf2025 text-left text-xl py-2 flex flex-col">
                        <h2 className='font-obviously font-black uppercase tracking-wider !text-lg !leading-4'>Activités familiales</h2>
                    </div>
                </a>

                <a className="main-page-project-box mb-6 group relative w-full rounded-3xl overflow-hidden row-span-1 col-span-1 border-4 border-purple-wolf2025 bg-purple-wolf2025" 
                    href="/conferencesetateliers" >
                    <StaticImage src="../../../images/prog/conferencesetateliers.jpg" alt="Kiosque de développement durable." className="w-full h-full object-cover" />
                    <div className="main-page-project-box-inner absolute bottom-[-1px] left-0 w-full my-auto bg-blue-wolf2025 text-left text-xl py-2 flex flex-col">
                        <h2 className='font-obviously font-black uppercase tracking-wider !text-lg !leading-4'>Conférences et ateliers</h2>
                    </div>
                </a>

                <a className="main-page-project-box mb-6 group relative w-full rounded-3xl overflow-hidden row-span-1 col-span-1 border-4 border-purple-wolf2025 bg-purple-wolf2025" 
                    href="/bienetre" >
                    <StaticImage src="../../../images/prog/bienetre.jpg" alt="Séance de yoga dans le Jardin de François" className="w-full h-full object-cover" />
                    <div className="main-page-project-box-inner absolute bottom-[-1px] left-0 w-full my-auto bg-yellow-wolf2025 text-left text-xl py-2 flex flex-col">
                        <h2 className='font-obviously font-black uppercase tracking-wider !text-lg !leading-auto'>Bien-être</h2>
                    </div>
                </a>

                <a className="main-page-project-box mb-6 group relative w-full rounded-3xl overflow-hidden row-span-1 col-span-1 border-4 border-purple-wolf2025 bg-purple-wolf2025" 
                    href="/plaisirsdivers" >
                    <StaticImage src="../../../images/prog/plaisirsdivers.jpg" alt="Personne avec une casque de réalité virtuelle." className="w-full h-full object-cover" />
                    <div className="main-page-project-box-inner absolute bottom-[-1px] left-0 w-full my-auto bg-pink-wolf2025 text-left text-xl py-2 flex flex-col">
                        <h2 className='font-obviously font-black uppercase tracking-wider !text-lg !leading-5'>Plaisirs divers</h2>
                    </div>
                </a>

            </div>
        </div>
    </center>
  )
}

export default ArtistActivities
