import React, { useContext } from 'react'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import LikeButton from '../../Ui/LikeButton'
import { FilterContext } from '../Providers/FilterProvider'
import { getHours } from '../../../../utils/formattedDate'
import EmptyMessage from '../../Ui/EmptyMessage'

/**
 * @param eventsGroups
 */
const CalendarMobile = ({ eventsGroups }) => {

  function getStartTimeForAppearance (appearance) {
    return appearance?.data?.time ? appearance?.data?.time : appearance?.data?.event?.document?.data?.start_time
  }

  function byStartTime (appearance1, appearance2) {
    return new Date(getStartTimeForAppearance(appearance1)) - new Date(getStartTimeForAppearance(appearance2))
  }

  const { filter } = useContext(FilterContext)
  return (
    <div className="grid grid-cols-1">
      <div
        className={`event-artist lg:hidden min-h-[calc(100vh-84px)]`}
      >
        {eventsGroups?.map(({ start_date: startDate, appearances }) => {
          return (
            <div key={startDate}>
              <h2 className="flex justify-center bg-yellow-wolf2025 text-center font-obviously font-black text-purple-wolf2025 text-xl uppercase py-3 mobile-date border-y-2 border-purple-wolf2025">
                {startDate}
              </h2>
              <div>
                <EmptyMessage
                  className="text-center py-16 px-8"
                  condition={appearances?.length > 0}
                  dynamicMessage={filter}
                >
                  {appearances.map((appearancesGroup) => {
                    return appearancesGroup.sort(byStartTime).map((appearence) => {
                      const event = appearence.data?.event?.document?.data
                      const { title } = appearence.data
                      const appearenceId = appearence.id
                      const artist = appearence.data?.artist?.document?.data
                      const thumbMainImage = event?.is_show ? event?.main_image : artist?.main_image
                      const thumbImage = getImage(thumbMainImage)

                      let time = appearence.data.time
                      if (!time) {
                        time = appearence?.data?.event?.document?.data?.start_time
                      }

                      const free = event.free
                      return (
                        <div key={appearenceId}>
                          <div className="flex event items-center">
                            {thumbImage
                              ? (
                              <GatsbyImage
                                image={thumbImage}
                                alt={
                                  thumbMainImage?.alt
                                    ? thumbMainImage?.alt
                                    : ''
                                }
                                className="rounded-3xl h-full w-[30%] m-1"
                              />
                                )
                              : (
                              <StaticImage
                                selector="desktop-svg"
                                className="rounded-3xl h-full w-[30%] m-2"
                                src="https://images.prismic.io/le-festif-ca/a1bab4f9-aad2-4861-b053-b7b827642d05_placeholder.png?auto=compress,format"
                                alt={
                                  artist?.main_image.alt
                                    ? artist?.main_image.alt
                                    : ''
                                }
                              />
                                )}
                            <div className="py-6 flex flex-col w-[63%] justify-center ml-2 font-obviously font-regular tracking-tighter">
                              <h3 className={`${title.text === 'CRi' ? '' : 'uppercase'}  text-lg leading-5 mb-2`}>
                                {title ? title.text : event.title.text}
                              </h3>
                              <p className="uppercase">
                                {event?.stage?.document?.data?.title?.text}
                              </p>
                              <p className="uppercase font-normals">
                                {getHours(time)}
                                <span className="ml-1 italic">
                                  {free === true ? 'Gratuit' : ''}
                                </span>
                              </p>
                            </div>
                            <LikeButton
                              colorInverted={true}
                              uid={appearenceId}
                              className="z-10 w-[35px] mx-6"
                            />
                          </div>
                        </div>
                      )
                    })
                  })}
                </EmptyMessage>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default CalendarMobile
