import React, { useContext } from 'react'
import Calendar from './Calendar/calendar'
import Artists from './artists'
import { FilterContext } from './Providers/FilterProvider'

const ProgrammationContainer = ({ data }) => {
  const { calendar, bottomFilterRef } = useContext(FilterContext)
  return (
        <>
            <span ref={bottomFilterRef} className="relative -translate-y-24 block"/>
            {calendar ? <Calendar data={data} /> : <Artists data={data} />}
        </>
  )
}

export default ProgrammationContainer
