import React, { useContext } from 'react'
import CalendarMobile from './CalendarMobile'
import CalendarDesktop from './CalendarDesktop'
import { FilterContext } from '../Providers/FilterProvider'
import { EventsContext } from '../../Customer/Providers/CustomerEventsProvider'
import { formattedDate } from '../../../../utils/formattedDate'
import CalendarTabProvider from './Provider/CalendarTabProvider'
import { FILTER_FREE, FILTER_SELECTION, FILTER_ACTIVITY } from './Constants'

/**
 * @param data
 * @param type
 * @returns {JSX.Element}
 * @constructor
 */
const Calendar = ({ data, offsetHour = 4 }) => {
  const { filter, searchTerm } = useContext(FilterContext)
  const { includeUid } = useContext(EventsContext)

  /**
     * Sort Appearances by start date
     * Group all Appearances by same start date
     * Add to each group :
     * start_date_raw -> the group start date
     * end_date_raw -> the group end date
     * @type {*}
     */
  const groups = data.allPrismicAppearances.group
    .map(({ edges }) => {
      return (edges.map(({ node }) => {
        node.id = node.prismicId
        return node
      }))
    })
    .sort((a, b) => new Date(a[0].data?.event?.document?.data?.date_order).getTime() - new Date(b[0].data?.event?.document?.data?.date_order).getTime())
    .reduce((prev, current) => {
      // Sort current group element by start hour
      current.sort((a, b) => new Date(a.data?.time).getTime() - new Date(b.data?.time).getTime())

      const firstCurrentEvent = current[0].data?.event?.document?.data

      // Subtract 4 hours to the current group date so now a normal day on the calendar start at 4 AM and finish at 4 AM the next day
      const subHour = offsetHour || 4
      const date = formattedDate(new Date(firstCurrentEvent?.date_order).getTime() - (subHour * 60 * 60 * 1000), { day: '2-digit', month: '2-digit', year: 'numeric' })

      // Create the group (with the current date) if not exist
      if (!prev[date]) {
        prev[date] = []
        prev[date].start_date_raw = new Date(firstCurrentEvent?.date_order).getTime() - (offsetHour * 60 * 60 * 1000)
        prev[date].appearances = []
      }

      // Add current appearances to his group
      prev[date].appearances.push(current)
      return prev
    }, {})
    /**
     * Transform groups object to array
     * Add : formatted start_date
     * @type {{start_date_raw: *, end_date_raw: *, events: *, start_date: string}[]}
     */
    const eventsGroups = Object.keys(groups).map((e) => {
      return {
        start_date: formattedDate(groups[e].start_date_raw, { weekday: 'long', day: '2-digit', month: 'long' }),
        appearances: groups[e].appearances
          .map((appearances) => {
            return appearances.filter((appearance, i) => {
              const event = appearance.data?.event?.document?.data;
    
              // Gestion de `end_hour`
              if (appearance.data) {
                appearance.data.end_hour = (i + 1 !== appearances.length) ? appearances[i + 1].data?.time : event?.end_time;
              }

              // Remove events we don't want in the prog
              if (event?.hidefromprog === true) return false;
    
              // Filtrage des événements selon le critère sélectionné              
              const isFree = event?.free;
              const isRueFestive = appearance.data?.artist?.document?.data?.rue_festive_only || event?.title?.text === 'Rue Festive';
              let isMiscActivities = appearance.data?.artist?.document?.data?.misc_activities;
              isMiscActivities |= (!appearance.data?.artist?.document && event?.title?.text?.includes('DJ Set') === false);
    
              const matchesFilter = (
                !filter || // Aucun filtre actif
                (filter === FILTER_FREE && (isFree || isRueFestive)) ||
                (filter === FILTER_ACTIVITY && isMiscActivities) ||
                (filter === FILTER_SELECTION && includeUid(appearance.id))
              );
    
              // Filtrage par recherche
              const matchesSearch = !searchTerm || 
                (appearance.data?.artist?.document?.data?.title?.text?.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (event?.title?.text?.toLowerCase().includes(searchTerm.toLowerCase()));
    
              return matchesFilter && matchesSearch;
            });
          })
          .filter((appearances) => appearances.length !== 0)
      };
  }).filter((eventsGroup) => eventsGroup.appearances.length !== 0).map((item) => {
    const firstEvent = item.appearances[0][0]
    const lastEvent = item.appearances[item.appearances.length - 1][item.appearances[item.appearances.length - 1].length - 1]
    const endDateRaw = lastEvent.data.end_hour
    let startDateRaw = new Date(firstEvent.data.time).getTime() - (offsetHour * 60 * 60 * 1000)

    // For when it's a show_only event
    if (startDateRaw < 0) {
      startDateRaw = new Date(firstEvent.data.event.document.data.start_time).getTime() - (offsetHour * 60 * 60 * 1000)
    }
    item.start_date_raw = startDateRaw
    item.end_date_raw = endDateRaw

    return { ...item }
  })
  return (
        <section className="grid grid-cols-1 font-poppins block">
            <CalendarMobile eventsGroups={eventsGroups}/>
            <CalendarTabProvider>
                <CalendarDesktop stages={data.allPrismicStages} eventsGroups={eventsGroups}/>
            </CalendarTabProvider>
        </section>
  )
}

export default Calendar
