import React from 'react'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import { graphql } from 'gatsby'
import Wolflayout from '../components/wolflayout'
import BannerWithMobileAppDownloads from '../components/NewTheme/Ui/BannerWithMobileAppDownloads'
import ProgrammationFilter from '../components/NewTheme/Programmation/programmationFilter'
import ProgrammationContainer from '../components/NewTheme/Programmation/ProgrammationContainer'
import ColorButton from '../components/Ui/ColorButton'

const Programmation = ({ data, location }) => {
  const metaTitle = 'Programmation - Artistes | Le Festif! de Baie-Saint-Paul'
  const metaDescription = 'Programmation du Festif!'
  const metaKeywords = 'festival, musique, événement, spectacles, québec, charlevoix, programmation'
  return (
    <Wolflayout title="Programmation" mobileTitle="Prog">
      <FestifHelmet title={metaTitle}
                    description={metaDescription}
                    keywords={metaKeywords}
                    href={location.href} />

      <BannerWithMobileAppDownloads title="Programmation" />
      <ProgrammationFilter />
      <ProgrammationContainer data={data} />

      {/* SCENE BUTTON */}
      <div className="flex mx-auto pb-4 pt-12 text-center w-72">
        <ColorButton text="Découvre nos scènes!" href="/infos/scenes#info_content" />
      </div>

    </Wolflayout>
  )
}

export const query = graphql`
  query {
    allPrismicArtists(sort: { fields: data___order }) {
      edges {
        node {
          id
          uid
          slug
          data {
            title {
              text
            }
            main_image {
              url
              alt
              gatsbyImageData
            }
            rue_festive_only
            activite_diverses
            order
          }
        }
      }
    }
    allPrismicStages(filter: {data: {order: {gt: 0}}}, sort: {fields: data___order}) {
        edges {
        node {
          data {
            order
            title {
              text
            }
          }
          prismicId
        }
      }
    }
    allPrismicAppearances {
      group(field: data___event___id) {
        edges {
          node {
            id
            prismicId
            data {
              artist {
                document {
                  ... on PrismicArtists {
                    id
                    data {
                      title {
                        text
                      }
                      main_image {
                        alt
                        gatsbyImageData
                      }
                      rue_festive_only
                      activite_diverses
                    }
                  }
                }
              }
              event {
                document {
                  ... on PrismicEvents {
                    id
                    data {
                      is_show
                      hidefromprog
                      free
                      title {
                        text
                      }
                      date_order: start_time
                      start_time
                      end_time
                      stage {
                        document {
                          ... on PrismicStages {
                            data {
                              title {
                                text
                              }
                            }
                          }
                        }
                      }
                      main_image {
                        alt
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
              title {
                text
              }
              time
            }
          }
        }
      }
    }
  }
`

export default Programmation
