import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import { useGesture } from '@use-gesture/react'

/**
 * A horizontal scroll view component.
 *
 * @param {Object} props - The component's props.
 * @param {boolean} [props.useWheel=false] - Whether or not to enable scrolling via mouse wheel. Defaults to `false`.
 * @param {boolean} [props.showScrollbar=false] - Whether or not to show the scrollbar. Defaults to `false`.
 * @param {boolean} [props.showArrow=false] - Whether or not to show the arrows. Defaults to `false`.
 * @param {int} [props.arrowPower=1] - A value used to determine the power of the arrow click scroll distance. Defaults to `1`.
 * @param {boolean} [props.activateNativeScroll=false] - Whether or not to activate the native scrollbar handler. Defaults to `false`.
 * @param {string} [props.className] - A value used to add css class to the component content div.
 * @param {string} [props.scrollbarClassName] - A value used to add style class to the component scrollbar div.
 * @param {string} [props.notOverlappingClassName] - css Class to add to the component content div when the view is not scrollable.
 * @param {string} [props.overlappingClassName] - css Class to add to the component content div when the view is scrollable.
 * @param {string} [props.arrowClassName] - css Class to add to the component arrows when they are  activated.
 * @param {string} [props.updateIndex] - A value used to trigger updates to the component. Can be any value.
 * @param {Object} [props.children] - The component's child elements.
 */
export const HorizontalScrollView = (props) => {
  // Initialize refs for the content area and the scrollbar.
  const ref = React.useRef()
  const scrollRef = React.useRef()

  // Initialize state for the component's x-position and the scrollbar's x-position.
  const [{ x }, api] = useSpring(() => ({ x: 0 }))
  const [scrollPositionX, setScrollPositionX] = useState(0)

  // Initialize state for the width of the scrollbar, the scroll container, and the content area.
  const [scrollWidth, setScrollWidth] = useState(0.1)
  const [scrollContainerWidth, setScrollContainerWidth] = useState(scrollRef.current?.clientWidth)
  const [viewWidth, setViewWidth] = useState(ref.current?.clientWidth)
  const [viewScrollWidth, setViewScrollWidth] = useState(ref.current?.clientWidth)

  const updateScrollPositionX = (forNativeScroll = false) => {
    const currentRef = ref.current
    if ((props.showScrollbar && !props.activateNativeScroll) || (props.showScrollbar && forNativeScroll)) { setScrollPositionX(currentRef?.scrollLeft * 100 / viewScrollWidth) }
  }

  /**
     * Update view and scroll X position
     * @param offset
     * @param immediate
     */
  const navigate = (offset, immediate = false) => {
    updateScrollPositionX()
    api.start({ x: offset, immediate })
  }

  // Update the scrollbar and content area widths on component mount and update.
  useEffect(() => {
    const currentRef = ref.current

    setScrollContainerWidth(scrollRef?.current?.clientWidth)
    setViewWidth(currentRef?.clientWidth)
    setViewScrollWidth(currentRef?.scrollWidth)
    setScrollWidth((currentRef?.clientWidth * 100 / currentRef?.scrollWidth))
  }, [props.updateIndex, ref, scrollRef, scrollWidth])

  // Reset scroll position on component update
  useEffect(() => {
    setScrollPositionX(0)
    api.start({ x: 0, immediate: true })
  }, [props.updateIndex, api])

  // Define the gesture handling for the component's content area.
  const gesture = {
    onDrag: ({ down, offset: [ox] }) => {
      navigate(-ox, down)
    }
  }

  // Define the gesture handling for the scrollbar.
  const scrollGesture = {
    onDrag: ({ down, offset: [ox] }) => {
      const calculatedOX = ox / (((scrollContainerWidth || 0) * 100 / viewWidth) / 100)
      navigate(calculatedOX, down)
    }
  }

  // If enabled, add a wheel listener for scrolling the content horizontally
  if (props.useWheel) {
    gesture.onWheel = ({ event, offset: [, oy] }) => {
      event.preventDefault()
      navigate(oy, true)
    }
  }

  if (props.activateNativeScroll) {
    gesture.onScroll = () => {
      updateScrollPositionX(true)
    }
  }

  // Add the scrollbar drag gesture
  useGesture(
    props.showScrollbar ? { ...scrollGesture } : {},
    {
      target: scrollRef,
      drag: {
        axis: 'x',
        from: () => {
          const currentRef = ref.current
          const calculatedScrollLeft = currentRef?.scrollLeft * (((scrollContainerWidth) * 100 / viewWidth) / 100)
          return [calculatedScrollLeft, 0]
        }
      }
    }
  )

  // Add the content drag gesture
  useGesture(
    { ...gesture },
    {
      target: ref,
      wheel: {
        eventOptions: { passive: false },
        axis: 'y',
        from: () => {
          return [0, ref.current.scrollLeft]
        }
      },
      scroll: {
        axis: 'x',
        from: () => {
          return [ref.current.scrollLeft, 0]
        }
      },
      drag: {
        axis: 'x',
        filterTaps: true,
        from: () => {
          return [-ref.current.scrollLeft, 0]
        }
      }
    }
  )

  return (
        <div className="relative overflow-hidden w-full">
            {
                props.showArrow && viewWidth < viewScrollWidth
                  ? <button onClick={(e) => {
                    e.preventDefault()
                    navigate(ref?.current?.scrollLeft - (viewWidth * (props.arrowPower ? props.arrowPower : 1)))
                  }} className={`z-50 bg-purple-wolf2025/80 ${props.arrowClassName ? props.arrowClassName : ''} ${props.showScrollbar ? 'h-[calc(100%-1.25rem)]' : 'h-full'} absolute text-white left-0 top-0 cursor-pointer`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left w-8 h-8">
                            <path d="M15 18l-6-6 6-6" />
                        </svg>
                    </button>
                  : null
            }

            <animated.div
            ref={ref}
            scrollLeft={x}
            className={`${props.className} ${viewWidth >= viewScrollWidth ? props.notOverlappingClassName : props.overlappingClassName} ${props.activateNativeScroll ? 'overflow-x-auto overflow-hidden scrollbar-hide' : 'overflow-hidden'} select-none cursor-grab h-full touch-none`}
            >
                {props.children}
            </animated.div>
            {
                props.showArrow && viewWidth < viewScrollWidth
                  ? <button onClick={(e) => {
                    e.preventDefault()
                    navigate(ref?.current?.scrollLeft + (viewWidth * (props.arrowPower ? props.arrowPower : 1)))
                  }} className={`z-50 bg-purple-wolf2025/80 ${props.arrowClassName ? props.arrowClassName : ''} ${props.showScrollbar ? 'h-[calc(100%-1.25rem)]' : 'h-full'} absolute text-white right-0 top-0 cursor-pointer z-50`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right w-8 h-8">
                            <path d="M9 18l6-6-6-6" />
                        </svg>
                    </button>
                  : null
            }
            {
                props.showScrollbar && viewWidth < viewScrollWidth
                  ? <animated.div ref={scrollRef} style={{ left: `${scrollPositionX}%`, width: `${scrollWidth}%` }} className={`${props.scrollbarClassName} touch-none mt-4 bg-white/30 hover:bg-white/80 transition-colors rounded-full absolute bottom-0 left-0 h-4 z-10`}>

                </animated.div>
                  : null
            }
        </div>

  )
}
